import React from 'react'
import LiveAttendanceViewModel from '../../view-models/live-attendance-view-model'
import closeIcon from '../../../assets/icons/cross.png'
import cameraSwitchIcon from '../../../assets/icons/camera-switch.png'
import editIcon from '../../../assets/icons/edit.png'
import cameraFrame from '../../../assets/icons/corner-border-frame.png'

const LiveAttendance = () => {
    const {
        isLoading,
        videoRef,
        handleCloseLiveAttendance,
        handleCaptureLoop,
        capturedImage,
        switchCamera,
        devices,
        capturedImageBestMatch,
        countdown,
        toggleSidePanel,
        isSidePanelOpen,
        isMultipleFaceDetected,
        canvasRef,
        boxSize,
        isDaysAttendanceFetching,
        daysAttendance,
        isCapturedStudentDataFetching,
        capturedStudentData,
        isMatching,
    } = LiveAttendanceViewModel();

    return (
        <div>
            {isLoading && <div className='text-2xl font-semibold flex inset-0 justify-center items-center h-screen w-screen z-[100]'>Loading...</div>}
            <div className='inset-0 w-screen h-screen relative'>
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    onPlay={handleCaptureLoop}
                    style={{ width: window.innerWidth, height: window.innerHeight, objectFit: "cover", transform: "scaleX(-1)", display: "block" }}
                ></video>
                {(!isLoading && !capturedImage && !isMatching && !isMultipleFaceDetected) && (
                    <div 
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[95]'
                        style={{
                            height: boxSize,
                            width: boxSize,
                        }}
                    >
                        <img 
                            src={cameraFrame}
                            className='h-full w-full opacity-40 animate-pulse'
                        />
                    </div>
                )}
                <canvas ref={canvasRef} className='absolute top-0 left-0' style={{transform: "scaleX(-1)", display: "none"}}></canvas>
                {(capturedImage && countdown > 0) && (
                    <>
                        <div className='absolute top-0 left-0 w-full h-full z-10 backdrop-blur-lg'></div>
                        <div 
                            className='w-[400px] bg-slate-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 z-[120] rounded'
                        >
                            {/* <h3 className='text-center font-semibold text-gray-700'>Captured Image</h3> */}
                            <img src={capturedImage} alt="Captured Face" className='rounded' style={{transform: "scaleX(-1)"}} />
                            {(isCapturedStudentDataFetching || isMatching) ? (
                                <div className='w-full pt-2 flex flex-col gap-2 items-center'>
                                    <div className='h-6 w-[220px] bg-slate-200 animate-pulse rounded'></div>
                                    <div className='h-6 w-[160px] bg-slate-300 animate-pulse rounded'></div>
                                </div>
                            ) : (
                                <div className='w-full pt-2 flex flex-col gap-2 items-center'>
                                    {/* <div className='p-2 text-center font-semibold'>{capturedImageBestMatch?.name} - {capturedImageBestMatch?.similarity}</div> */}
                                    <div className='font-semibold text-lg text-gray-800'>{`${capturedStudentData?.attributes?.firstName} ${capturedStudentData?.attributes?.middleName ?? ''} ${capturedStudentData?.attributes?.lastName ?? ''}`}</div>
                                    <div className=''>{capturedStudentData?.attributes?.classes?.data[0]?.attributes?.class_name}</div>
                                </div>
                            )}
                        </div>
                        <div 
                            className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 text-center font-semibold z-[120] text-slate-200 text-2xl opacity-80'
                        >
                            {countdown}
                        </div>
                    </>
                )}
                {isMultipleFaceDetected && (
                    <div
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center border-4 border-red-400 p-8 font-semibold z-[20] rounded-lg text-red-400 text-2xl bg-black opacity-80 animate-pulse'
                    >
                        <div className='text-3xl underline'>Multiple Faces Detected!</div>
                        <div className='mt-2'>Please ensure only one student is in the frame for accurate recognition.</div>
                    </div>
                )}
                <div className='p-4 absolute bottom-0 w-full flex justify-center gap-4 hover:gap-10 scale-75 hover:scale-100 transition-all'>
                    <button 
                        className='disabled:opacity-50 disabled:cursor-not-allowed w-14 h-14 p-2 rounded-full bg-[#455a64] border-none z-100000 hover:scale-105 transition-all' 
                        onClick={switchCamera} 
                        disabled={devices.length <= 1}
                    >
                        <img 
                            src={cameraSwitchIcon}
                            className='w-full'
                        />
                    </button>
                    <button 
                        className='bg-red-500 w-14 h-14 p-1 border-none rounded-full z-100000 hover:scale-105 transition-all' 
                        onClick={handleCloseLiveAttendance}
                    >
                        <img 
                            src={closeIcon}
                            className='w-full'
                        />
                    </button>
                </div>
            </div>
            <div className={`absolute top-0 h-full z-[99] ${isSidePanelOpen ? 'left-0' : '-left-[400px]'} transition-all`}>
                <div 
                    className='h-14 w-14 hover:scale-125 transition-all rounded-full bg-slate-200 absolute bottom-0 left-[400px] transform -translate-x-1/2 -translate-y-1/2 -z-10 cursor-pointer'
                    onClick={toggleSidePanel}
                ></div>
                <div className='w-[400px] h-full bg-slate-200 p-2'>
                    <div className='font-semibold text-lg text-right px-2'>Students recongnized</div>
                    <hr className='my-2 border-gray-400'/>
                    <div className='flex flex-col gap-2 overflow-y-auto'>
                        {daysAttendance.map((_, index) => (
                            <div key={index} className='flex items-center rounded bg-slate-50 p-1'>
                                <div className='w-16 h-16 rounded bg-slate-300 animate-pulse'></div>
                                <div className='flex-grow flex flex-col gap-2 p-2'>
                                    <div className='w-32 h-5 rounded bg-slate-300 animate-pulse'></div>
                                    <div className='w-60 h-5 rounded bg-slate-300 animate-pulse'></div>
                                </div>
                                <div>
                                    <img 
                                        src={editIcon}
                                        className='w-6 mr-2 cursor-pointer'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveAttendance