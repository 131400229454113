import React, { useEffect } from 'react';
import AcademiesViewModel from '../../view-models/academies-view-model';
import Loader from '../bits/loader';
// import Section1 from './Section1'

const Companies = () => {
    const { allAcademies, isAllAcademiesLoading } = AcademiesViewModel();
    return (
        <div
            className="flex flex-col items-center"
            id="Customer"
        >
            <div className="text-center italic p-10">
                Serving 1000+ students enrolled in Sports and Creative Arts Academies
            </div>
            <div className="w-[100%] flex flex-col items-center">
                <div className="bg-white my-6 overflow-hidden relative w-[90vw] slider">
                    {isAllAcademiesLoading && <div className='flex items-center justify-center'><Loader/></div>}
                    <div className="flex w-[7500px] animate-scroll">
                        {[...Array(10)].map((_, repeatIndex) => (
                            <React.Fragment key={repeatIndex}>
                                {allAcademies?.map((academy, index) => {
                                    const logo = academy?.attributes?.logo?.data?.attributes?.url;
                                    return (
                                        logo && (
                                            <div
                                                key={index}
                                                className="h-[200px] w-[200px] flex items-center justify-center p-4"
                                            >
                                                <img
                                                    loading='lazy'
                                                    src={logo}
                                                    alt={`Logo ${index + 1}`}
                                                    className="h-full w-full object-contain"
                                                />
                                            </div>
                                        )
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companies;
