import React, { ReactNode, useEffect, useState } from 'react';
import style from './style.module.css';
import logo from '../../../../assets/logo.png';
import toggleOpenIcon from '../../../../assets/icons/menu.png';
import toggleCloseIcon from '../../../../assets/icons/cross.png';
// import notificationIcon from '../../../../assets/icons/bell.png';
import settingsIcon from '../../../../assets/icons/settings.png';
import dashboardIcon from '../../../../assets/icons/dashboard.png';
import academyIcon from '../../../../assets/icons/academy.png';
// import coachesIcon from '../../../../assets/icons/coaches.png';
import studentsIcon from '../../../../assets/icons/students.png';
import scheduleIcon from '../../../../assets/icons/schedule.png';
import progressIcon from '../../../../assets/icons/progress.png';
import feeIcon from '../../../../assets/icons/fee.png';
import enquiriesIcon from '../../../../assets/icons/enquiries.png';
import searchIcon from '../../../../assets/icons/search.png';
import { getCurrentDateFormatted } from '../../../../utils/helpers/helpers';
import HeaderMenuViewModel from '../../../view-models/header-menu-view-model';
import Loader from '../../bits/loader';
import { Routes } from '../../../navigation/routes';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import UserProfileIcon from '../../../../assets/icons/user-profile.png';
import AnnouncementIcon from '../../../../assets/icons/announcement.png';

// import {HEADER_DROPDOWN_LISTS} from './data';
// import DropdownList from '../dropdown-list';

import logoutviewmodel from '../../../view-models/logout-view-model';
import { useLocation } from 'react-router-dom';
import { Roles } from '../../../../utils/auth/roles';
import FullScreenLoader from '../../bits/fullscreen-loader';
import Webcam from 'react-webcam';
import VirtualMeetingView from '../virtual-meeting-view';

interface NavMenuProps {
    children?: ReactNode;
}
const NavMenu = ({ children }: NavMenuProps) => {
    const formattedDate = getCurrentDateFormatted();
    const location = useLocation();

    const { handleLogout } = logoutviewmodel();

    const {
        supplierData,
        isSupplierFetchSuccess,
        userData,
        handleOnMenuItemClick,
        handlesearchInputChange,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        goToStudentDetails,
        searchTerm,
        isSidebarOpen,
        handleToggleMenuClick,
        isDropdownOpen,
        handleAvatarClick,
        userRole,
        isUserDetailsDataFetchSuccess,
        userDetailsData,
        isWebDropdownOpen,
        handleSettingsIconClick,
        isSupplierDataLoading,
        isUserDetailsDataLoading,
        userState,
        isAttendanceOpen,
        handleAttendance,
        webcamRef,
        image,
        // capturePhoto
        activeMeeting,
    } = HeaderMenuViewModel();
    
    return (
        <div className={style.container}>
            {(isSupplierDataLoading || isUserDetailsDataLoading) && <FullScreenLoader />}
            {/* header */}
            <div className={style.headerContainer}>
                <div
                    className={style.sidebarToggleContainer}
                    onClick={handleToggleMenuClick}
                >
                    {isSidebarOpen ? (
                        <img
                            className={style.toggleIcon}
                            src={toggleCloseIcon}
                            alt="toggle Icon"
                        />
                    ) : (
                        <img
                            className={style.toggleIcon}
                            src={toggleOpenIcon}
                            alt="toggle Icon"
                        />
                    )}
                </div>
                <div className={style.headerFirstColumnWrapper}>
                    <div className={style.headerLogoContainer}>
                        <img
                            className={style.headerLogo}
                            src={logo}
                            alt="logo"
                        />
                        <div className={style.headerCurrentDate}>{formattedDate}</div>
                    </div>
                    <div className={style.headerWelcomeContainer}>
                        {userRole === Roles.AUTHENTICATED_USER && (
                            <div className={style.headerWelcomeMessage}>
                                Welcome {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''}
                                ,
                            </div>
                        )}
                        {userRole === Roles.RESTRICTED_USER && (
                            <div className={style.headerWelcomeMessage}>
                                Welcome {userDetailsData?.data?.data[0]?.attributes?.name},
                            </div>
                        )}
                        {userRole === Roles.AUTHENTICATED_USER && (
                            <div className={style.headerBusinessSummary}>
                                Here is a summary of your business
                            </div>
                        )}
                    </div>
                </div>

                {/* Attendance toggle */}
                {/* <div className='flex'>
                    <p>Default</p>
                        <div className={`p-1 w-20 rounded-md bg-[#455a64] flex ${isAttendanceOpen?'justify-end':''}`} onClick={handleAttendance}>
                            <button className='bg-white w-[25px] h-[25px] border-8 border-white rounded-md'
                                style={{ transition: 'transform 0.3s ease' }}></button>
                        </div>
                    <p>Attendance view</p>
                    <div className={`p-1 w-28 rounded-md bg-[#455a64] flex cursor-pointer text-white ${isAttendanceOpen?'justify-end':''}`} onClick={handleAttendance}>
                        <button className='bg-white w-[25px] h-[25px] border-8 border-white rounded-md'
                            style={{ transition: 'transform 0.3s ease' }}></button>
                        <p>Attendance</p>
                    </div>
                </div> */}

                <VirtualMeetingView />

                <div className={style.headerSecondColumnWrapper}>
                    <div className={`${style.headerIconContainerWrapper}`}>
                        <div
                            id="settings-icon"
                            className={style.headerIconContainer}
                            onClick={handleSettingsIconClick}
                        >
                            <img
                                className={style.headerIcon}
                                src={settingsIcon}
                                alt="settings"
                            />
                        </div>
                        {isWebDropdownOpen && (
                            <div className={style.settingsDropdownMenu}>
                                <ul>
                                    {(userState?.userDetails?.attributes?.userManagementAccess !==
                                        'noAccess' ||
                                        userState?.user?.role?.type ===
                                            Roles.AUTHENTICATED_USER) && (
                                        <>
                                            <li
                                                className={style.dropdownListItem}
                                                onClick={() =>
                                                    handleOnMenuItemClick(Routes.UserManagement)
                                                }
                                            >
                                                User Management
                                            </li>
                                            <li
                                                className={style.dropdownListItem}
                                                onClick={() =>
                                                    handleOnMenuItemClick(Routes.Settings)
                                                }
                                            >
                                                Settings
                                            </li>
                                        </>
                                    )}
                                    <li
                                        className={style.dropdownListItem}
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </li>
                                </ul>
                            </div>
                        )}

                        {/* <div
                            className={`${style.headerDropDownWrapper} group-hover:flex`}>
                            <span
                                onClick={handleLogout}
                                className={`${style.headerDropDownText}`}>
                                Logout
                            </span>
                        </div> */}

                        {/* <div className={style.dropdownListContainer}>
                            <ul>
                                <li className={style.dropdownlistItem} >  </li>
                            </ul>
                        </div>  */}

                        {/* temporarily commented out for demo */}

                        {/* <div className={style.headerIconContainer}>
                            <img
                                className={style.headerIcon}
                                src={notificationIcon}
                                alt="notification"
                            />
                        </div> */}

                        {/* temporarily commented out for demo */}
                    </div>
                    {userRole === Roles.AUTHENTICATED_USER &&
                        (isSupplierFetchSuccess ? (
                            <div className={style.headerUserContainer}>
                                <div
                                    className={style.headerUserAvatarContainer}
                                    onClick={handleAvatarClick}
                                >
                                    <img
                                        className={style.headerUserAvatar}
                                        src={
                                            supplierData?.data?.data?.[0]?.attributes?.photo?.data
                                                ?.attributes?.url ?? UserProfileIcon
                                        }
                                        alt="avatar"
                                    />
                                    {isDropdownOpen && (
                                        <div className={style.dropdownMenu}>
                                            <ul>
                                                <li
                                                    className={style.dropdownListItem}
                                                    onClick={() =>
                                                        handleOnMenuItemClick(Routes.UserManagement)
                                                    }
                                                >
                                                    User Management
                                                </li>
                                                <li
                                                    className={style.dropdownListItem}
                                                    onClick={() =>
                                                        handleOnMenuItemClick(Routes.Settings)
                                                    }
                                                >
                                                    Settings
                                                </li>
                                                <li
                                                    className={style.dropdownListItem}
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className={style.headerUserDetailsContainer}>
                                    <div className={style.headerUserName}>
                                        {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''}{' '}
                                        {supplierData?.data?.data?.[0]?.attributes?.lastName ?? ''}
                                    </div>
                                    <div className={style.headerUserEmail}>
                                        {userData?.email ?? ''}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        ))}
                    {userRole === Roles.RESTRICTED_USER &&
                        (isUserDetailsDataFetchSuccess ? (
                            <div className={style.headerUserContainer}>
                                <div
                                    className={style.headerUserAvatarContainer}
                                    onClick={handleAvatarClick}
                                >
                                    <img
                                        className={style.headerUserAvatar}
                                        src={
                                            userDetailsData?.data?.data[0]?.attributes?.photo?.data
                                                ?.attributes?.url ?? UserProfileIcon
                                        }
                                        alt="avatar"
                                    />
                                    {isDropdownOpen && (
                                        <div className={style.dropdownMenu}>
                                            <ul>
                                                {userState?.userDetails?.attributes
                                                    ?.userManagementAccess !== 'noAccess' && (
                                                    <li
                                                        className={style.dropdownListItem}
                                                        onClick={() =>
                                                            handleOnMenuItemClick(
                                                                Routes.UserManagement
                                                            )
                                                        }
                                                    >
                                                        User Management
                                                    </li>
                                                )}
                                                <li
                                                    className={style.dropdownListItem}
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className={style.headerUserDetailsContainer}>
                                    <div className={style.headerUserName}>
                                        {userDetailsData?.data?.data[0]?.attributes?.name}
                                    </div>
                                    <div className={style.headerUserEmail}>
                                        {userDetailsData?.data?.data[0]?.attributes?.email}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        ))}
                </div>
            </div>

            <div className={style.contentContainer}>
                {/* menu */}
                <div className={`${style.menuContainer} ${isSidebarOpen && style.sideMenuActive}`}>
                    {/* {userRole === Roles.AUTHENTICATED_USER && */}
                    <>
                        <div className={style.menuTitle}>Menu</div>
                        <div
                            id="dashboard"
                            className={`${style.menuItemContainer} ${
                                location.pathname === '/dashboard' ? style.activeMenuItem : ''
                            }`}
                            onClick={() => handleOnMenuItemClick(Routes.Dashboard)}
                        >
                            <img
                                className={style.menuIcon}
                                src={dashboardIcon}
                                alt="dashboard"
                            />
                            <div className={style.menuItemLabel}>Dashboard</div>
                        </div>
                        <div
                            id="academy-management"
                            className={`${style.menuItemContainer} ${
                                location.pathname === '/academies' ? style.activeMenuItem : ''
                            }`}
                            onClick={() => handleOnMenuItemClick(Routes.Academies)}
                        >
                            <img
                                className={style.menuIcon}
                                src={academyIcon}
                            />
                            <div className={style.menuItemLabel}>Academy Management</div>
                        </div>
                        <div className={style.menuTitle}>More</div>
                        {(userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' ||
                            userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                            <div
                                id="search"
                                className={style.menuSearchInputContainer}
                            >
                                <input
                                    className={style.menuSearchInput}
                                    placeholder="Search students by name"
                                    onChange={handlesearchInputChange}
                                />
                                <span className={style.menuSearchIconContainer}>
                                    <img
                                        className={style.menuSearchIcon}
                                        src={searchIcon}
                                        alt="search"
                                    />
                                </span>
                                <span className={style.menuSearchLoaderContainer}>
                                    {isStudentDataFetchLoading && (
                                        <div className={style.menuSearchLoader}></div>
                                    )}
                                </span>

                                {/* { isStudentSuggesionDataFetchSuccess ? ( */}

                                {searchTerm && (
                                    <div className={style.suggestionBox}>
                                        <ul className={style.suggestionBoxListWrapper}>
                                            {(studentSuggestionListData?.data?.data ?? []).map(
                                                (item, index) => (
                                                    <li
                                                        className={style.suggestionBoxListItem}
                                                        key={index}
                                                        onClick={goToStudentDetails.bind(
                                                            this,
                                                            item.id
                                                        )}
                                                    >
                                                        <span>
                                                            <img
                                                                className={
                                                                    style.studentProfileWrapper
                                                                }
                                                                src={
                                                                    item?.attributes?.photo?.data
                                                                        ?.attributes?.url
                                                                        ? item?.attributes?.photo
                                                                              ?.data?.attributes
                                                                              ?.url
                                                                        : StudentProfileIcon
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            {item?.attributes?.firstName +
                                                                ' ' +
                                                                item?.attributes?.lastName}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}

                                {/* </div> ) : ( <div className={style.suggestionBox}> 
                                                <div className={style.loaderWrap}>        
                                                    <Loader/>
                                                </div>
                                        </div> ) } */}
                            </div>
                        )}
                        <div
                            id="enquiry-navMenu"
                            className={`${style.menuItemContainer} ${
                                location.pathname === '/enquires' ? style.activeMenuItem : ''
                            }`}
                            onClick={() => handleOnMenuItemClick(Routes.Enquires)}
                        >
                            <img
                                className={style.menuIcon}
                                src={enquiriesIcon}
                                alt="Enquires Icon"
                            />
                            <div className={style.menuItemLabel}>Enquiries</div>
                        </div>
                        <div
                            id="students-navmenu"
                            className={`${style.menuItemContainer} ${
                                location.pathname === '/students' ? style.activeMenuItem : ''
                            }`}
                            onClick={() => handleOnMenuItemClick(Routes.Students)}
                        >
                            <img
                                className={style.menuIcon}
                                src={studentsIcon}
                                alt="Students Icon"
                            />
                            <div className={style.menuItemLabel}>Students</div>
                        </div>
                        <div
                            id="feepayment-navmenu"
                            className={`${style.menuItemContainer} ${
                                location.pathname === '/fee-payment' ? style.activeMenuItem : ''
                            }`}
                            onClick={() => handleOnMenuItemClick(Routes.FeePayment)}
                        >
                            <img
                                className={style.menuIcon}
                                src={feeIcon}
                                alt=""
                            />
                            <div className={style.menuItemLabel}>Fee Payment</div>
                        </div>
                        {/* <div 
                                className={`${style.menuItemContainer} ${location.pathname === '/coaches' ? style.activeMenuItem : ''}`}
                                onClick={() => handleOnMenuItemClick(Routes.UserManagement)}
                            >
                                <img
                                    className={style.menuIcon}
                                    src={coachesIcon}
                                    alt="coaches"
                                />
                                <div className={style.menuItemLabel}>Coaches</div>
                            </div> */}
                    </>
                    {/* } */}

                    <div
                        id="schedule-navmenu"
                        className={`${style.menuItemContainer} ${
                            location.pathname === '/schedule' || location.pathname.startsWith('/schedule-details/') ? style.activeMenuItem : ''
                        }`}                        
                        onClick={() => handleOnMenuItemClick(Routes.Schedule)}
                    >
                        <img
                            className={style.menuIcon}
                            src={scheduleIcon}
                            alt="schedule"
                        />
                        <div className={style.menuItemLabel}>Schedule</div>
                    </div>
                    <div
                        id="progress-tracker-navmenu"
                        className={`${style.menuItemContainer} ${location.pathname === '/progress-tracker' ? style.activeMenuItem : ''}`}
                        onClick={() => handleOnMenuItemClick(Routes.ProgressTracker)}
                    >
                        <img
                            className={style.menuIcon}
                            src={progressIcon}
                            alt="schedule"
                        />
                        <div className={style.menuItemLabel}> Progress Tracker</div>
                    </div>
                    <div
                        id="announcement-navmenu"
                        className={`${style.menuItemContainer} ${location.pathname === '/announcement' ? style.activeMenuItem : ''}`}
                        onClick={() => handleOnMenuItemClick(Routes.Announcement)}
                    >
                        <img
                            className={style.menuIcon}
                            src={AnnouncementIcon}
                            alt="schedule"
                        />
                        <div className={style.menuItemLabel}> Announcement</div>
                    </div>
                    <div
                        id="virtual-class-navmenu"
                        className={`${style.menuItemContainer} ${location.pathname === '/virtual-class' ? style.activeMenuItem : ''}`}
                        onClick={() => handleOnMenuItemClick(Routes.VirtualClass)}
                    >
                        <img
                            className={style.menuIcon}
                            src='/assets/images/virtual.png'
                            alt="virtual-class"
                        />
                        <div className={style.menuItemLabel}> Virtual Class</div>
                    </div>
                </div>

                {/* children */}
                <div className={style.childrenContainer}>{children}</div>
            </div>

            {isAttendanceOpen && <div className='absolute z-[2000] inset-0 w-screen h-screen'>
                    {/* Webcam component */}
                    {/* {<Webcam
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        className="w-full h-full object-cover"
                        videoConstraints={{ facingMode: "user" }} // Default front camera
                        imageSmoothing={true}
                    />} */}
                <video ref={webcamRef} width="720" height="560" className="w-full h-full object-cover" autoPlay />
                <canvas className="w-full h-full top-0 left-0 absolute" />

                {/* Button to capture photo */}
                <div className='p-4 absolute bottom-0 w-full flex justify-center gap-4'>
                    {/* <button className='bg-[#f04242] text-white w-20 h-20 border-none rounded-[3rem]' onClick={capturePhoto}>Capture</button> */}
                    <button className='w-20 h-20 bg-[#455a64] border-none rounded-[3rem] text-white z-10' onClick={handleAttendance}>Close</button>
                </div>

                {/* Display the captured photo */}
                {image && <div className='absolute bottom-0 right-[5%] w-1/4 h-1/4'>
                    <img src={image} alt="Captured" />
                </div>}
            </div>}

        </div>
    );
};

export default NavMenu;
