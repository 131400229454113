import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as faceapi from '@vladmandic/face-api';
import pklData from '../components/widgets/nav-menu/pkl.json';
import { UploadService } from '../../services/upload-service';
import { base64ToImage, cosineSimilarity } from '../../utils/helpers/helpers';
import { LIVE_ATTENDANCE_OF_DAY_QUERY, STUDENTS_TRAINED_FACE_DATA } from '../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UtilityService } from '../../services/utility-service';
import { RootState } from '../../utils/redux/store';
import { useSelector } from 'react-redux';
import { TrainedStudentFaceData } from '../../models/attendance/trained-student-face-data';
import ScheduleService from '../../services/schedule-service';
import AttendanceRequest from '../../utils/types/attendance-request';
import { AttendanceDataWraper } from '../../models/attendance/attendance-data-wrapper';
import { StudentService } from '../../services/student-service';

type MatchResult = {
  studentId: string;
  name: string;
  academyId: string;
  classId: string;
  similarity: number;
};

const LiveAttendanceViewModel = () => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();
    const [activeAcademyId, setActiveAcademyId] = useState<number>(1);
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const navigate = useNavigate();
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [isMatching, setIsMatching] = useState(false);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [capturedImageBestMatch, setCapturedImageBestMatch] = useState<MatchResult | null>(null);
    const [countdown, setCountdown] = useState<number>(100);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isMultipleFaceDetected, setIsMultipleFaceDetected] = useState(false);
    const [boxSize, setBoxSize] = useState(500);

    const [testingUserId, setTestingUserId] = useState<number>(1);

    // useEffect(() => {
    //     const id = window.prompt("Enter user id", "");
    //     if (id) {
    //         setTestingUserId(parseInt(id));
    //     }else{
    //         navigate(-1);
    //     }
    // }, []);

    const { isLoading: isTrainedStudentsFetching, data: trainedStudentsData } = useQuery({
        queryKey: [STUDENTS_TRAINED_FACE_DATA, testingUserId],
        // queryFn: () => UtilityService.instance.getStudentsTrainedFaceData(userState?.user?.id ?? 0),
        queryFn: () => UtilityService.instance.getStudentsTrainedFaceData(testingUserId),
        refetchOnWindowFocus: false,
        enabled: (testingUserId ?? 0) > 0,
    });

    const getVideoDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === "videoinput");
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          setCurrentDeviceId(videoDevices[0].deviceId);
        }
      } catch (err) {
        console.error("Error enumerating devices", err);
      }
    };

    useEffect(() => {
        const loadModels = async () => {
            try {
                const MODEL_URL = '/weights';
                // const MODEL_URL = 'https://cdn.jsdelivr.net/gh/justadudewhohacks/face-api.js@master/weights';
                // await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
                await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
                await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
                await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
                await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
                setIsModelLoaded(true);
            } catch (err) {
              console.error("Error loading models:", err);
            }
        };
        
        getVideoDevices();    
        loadModels();
    }, []);

    useEffect(() => {
        if (isModelLoaded) {
            startVideo();
        }
    }, [isModelLoaded]);

    const switchCamera = () => {
      if (devices.length > 1) {
        const currentIndex = devices.findIndex((device) => device.deviceId === currentDeviceId);
        const nextIndex = (currentIndex + 1) % devices.length; // Cycle through devices
        const nextDeviceId = devices[nextIndex].deviceId;
        setCurrentDeviceId(nextDeviceId);
        startVideo(nextDeviceId);
      }
    };

    const handleCloseLiveAttendance = () => {
        navigate(-1);
    }

    const startVideo = async (deviceId: string | null = null) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            facingMode: "user",
          },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      } catch (err) {
        console.error("Error accessing the camera", err);
      }
    };

    const handleCaptureLoop = async () => {
        if (!isModelLoaded || !videoRef.current || !trainedStudentsData?.data?.data?.data) return;
    
        const video = videoRef.current;
    
        const updateDimensions = () => {
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
    
            return {
                boxX: (videoWidth - boxSize) / 2,
                boxY: (videoHeight - boxSize) / 2,
            };
        };
    
        let { boxX, boxY } = updateDimensions();
    
        const captureLoop = async () => {
            if (isMatching || capturedImage) return;
    
            if (!canvasRef.current) return;
            canvasRef.current.width = boxSize;
            canvasRef.current.height = boxSize;
    
            const context = canvasRef.current.getContext("2d");
            if (!context) {
                console.error("Unable to get canvas context");
                return;
            }
    
            ({ boxX, boxY } = updateDimensions());
    
            context.drawImage(video, boxX, boxY, boxSize, boxSize, 0, 0, boxSize, boxSize);
            const detections = await faceapi.detectAllFaces(canvasRef?.current);
            if (detections.length > 0) {
                if (detections.length === 1) {
                    const faceBox = detections[0].box;
                    const margin = 0.5;
                    const expandedX = Math.max(0, faceBox.x - faceBox.width * margin);
                    const expandedY = Math.max(0, faceBox.y - faceBox.height * margin);
                    const expandedWidth = Math.min(boxSize, faceBox.width + faceBox.width * margin * 2);
                    const expandedHeight = Math.min(boxSize, faceBox.height + faceBox.height * margin * 2);
                    const croppedCanvas = document.createElement("canvas");
                    croppedCanvas.width = expandedWidth;
                    croppedCanvas.height = expandedHeight;

                    const croppedContext = croppedCanvas.getContext("2d");
                    if (croppedContext) {
                        // Draw the expanded bounding box area onto the new canvas
                        croppedContext.drawImage(
                            canvasRef.current,
                            expandedX,
                            expandedY,
                            expandedWidth,
                            expandedHeight,
                            0,
                            0,
                            expandedWidth,
                            expandedHeight
                        );

                        // Convert the cropped face to a Data URL
                        const croppedImageDataUrl = croppedCanvas.toDataURL("image/png");
                        setIsMultipleFaceDetected(false);
                        captureImage(croppedImageDataUrl);
                    }
                } else {
                    setIsMultipleFaceDetected(true);
                }
            } else {
                setIsMultipleFaceDetected(false);
            }
        };
    
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(captureLoop, 1000);
    };

    useEffect(() => {
        if (capturedImage) {
            // Stop the interval when an image is captured
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }

            // Restart the capture process after 10 seconds
            const timeout = setTimeout(() => {
                setCapturedImage(null); // Reset capturedImage
                setCountdown(100);
            }, 100000);

            const countdownInterval = setInterval(() => {
              setCountdown((prev) => {
                if (prev <= 1) {
                  clearInterval(countdownInterval); // Stop the countdown when it reaches 0
                  return 0;
                }
                return prev - 1;
              });
            }, 1000);

            return () => {
              clearTimeout(timeout); // Cleanup timeout on unmount or when the image is reset
              clearInterval(countdownInterval); // Cleanup countdown interval
            };
        } else if (!intervalRef.current) {
            // Restart the interval if capturedImage is null
            handleCaptureLoop();
        }
    }, [capturedImage, trainedStudentsData?.data?.data?.data]);
    
    // const drawFaceBox = async (canvas: HTMLCanvasElement, video: HTMLVideoElement) => {
    //     const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions());
    //     const context = canvas.getContext("2d");
    
    //     if (context) {
    //       detections.forEach(det => {
    //           const { x, y, width, height } = det.box;
    //           context.strokeStyle = "#00FF00";
    //           context.lineWidth = 2;
    //           context.strokeRect(x, y, width, height);
    //       });
    //     }
    // };

    const captureImage = async (image: string) => {
      setCapturedImage(image);
  
      try {
          setIsMatching(true);
          const inputFace = await getFaceEmbedding(image);
          const bestMatch = findBestMatch(
              // trainedStudentsData?.data?.data?.data ?? {},
              pklData,
              inputFace
          );
          setCapturedImageBestMatch(bestMatch);
      } catch (error) {
          console.error("Error detecting face:", error);
      } finally {
          setIsMatching(false);
          setTimeout(() => {
              setCapturedImage(null);
          }, 100000);
      }
    };
  

    const extractFaceEmbedding = (face: number[][]): number[] => {
        return face.flat() as number[];
    };

    const findBestMatch = (data: TrainedStudentFaceData, inputFace: number[]): MatchResult | null => {
      let bestMatch: MatchResult | null = null;
      let highestSimilarity = -1;

      // Loop through the data structure
      for (const academyId in data) {
        for (const classId in data[academyId]) {
          for (const studentId in data[academyId][classId]) {
            const studentData = data[academyId][classId][studentId];
            const faceData = studentData.face;
  
            if (faceData && faceData.length > 0) {
              // Extract the embedding for this student
              const faceEmbedding = extractFaceEmbedding(faceData);
              // Compute similarity
              const similarity = cosineSimilarity(faceEmbedding, inputFace);
              // Check if this is the best match so far
              if (similarity > highestSimilarity) {
                highestSimilarity = similarity;
                bestMatch = {
                  studentId,
                  name: studentData.name,
                  academyId,
                  classId,
                  similarity,
                };
              }
            }
          }
        }
      }
  
      return bestMatch;
    };

    const getFaceEmbedding = async (base64Image: string) => {
        const img = await base64ToImage(base64Image);

        // Ensure TypeScript knows img is an HTMLImageElement
        const detections = await faceapi.detectAllFaces(img as HTMLImageElement).withFaceLandmarks().withFaceDescriptors();

        // Assuming there is at least one face detected, we can extract the face descriptor
        if (detections.length > 0) {
            const faceDescriptor = detections[0].descriptor; // This is a Float32Array
            const inputFace = Array.from(faceDescriptor); // Convert Float32Array to number[]
            return inputFace;
        } else {
            throw new Error('No face detected');
        }
    };

    const toggleSidePanel = () => {
        setIsSidePanelOpen(prev => !prev);
    }

    const { isLoading: isDaysAttendanceFetching, data: daysAttendance } = useQuery({
        queryKey: [LIVE_ATTENDANCE_OF_DAY_QUERY],
        queryFn: () => ScheduleService.instance.getLiveAttendanceOfDay(activeAcademyId, new Date().toISOString().slice(0, 10)),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const { isLoading: isCapturedStudentDataFetching, data: capturedStudentData } = useQuery({
        queryKey: [capturedImageBestMatch?.studentId],
        queryFn: () => StudentService.instance.getStudentDataForLiveAttendance(Number(capturedImageBestMatch?.studentId) ?? 0),
        refetchOnWindowFocus: false,
        enabled: (capturedImageBestMatch?.studentId !== undefined),
    });

    const postAttendance = async (data: AttendanceRequest): Promise<AttendanceDataWraper | undefined> => {
        const response = await ScheduleService.instance.postAttendance(data);
        if (response.success) {
            return response?.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addAttendance,
        isLoading: isAttendanceAdding,
    } = useMutation(postAttendance, {
        onSuccess: data => {
            
            queryClient.invalidateQueries(LIVE_ATTENDANCE_OF_DAY_QUERY);
        },
        onError: error => {
            console.log(`On class add Error:`, error);
        },
    });

    const uploadCapturedImage = async () => {
      if(capturedImage){
        try {
            const base64String = capturedImage.split(',')[1];
            const mimeType = capturedImage.match(/data:(.*?);base64/)?.[1] || 'image/png';
        
            // Convert Base64 to binary data
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
        
            // Create a Blob and then a File
            const blob = new Blob([byteArray], { type: mimeType });
            const file = new File([blob], 'image.png', { type: mimeType });
        
            // Append to FormData
            const formData = new FormData();
            formData.append('files', file);
            const response = await UploadService.instance.uploadImage(formData);

            if (response.success && response?.data && response?.data?.length > 0) {
                return response?.data[0]?.id;
            }else{
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postImageUpload: ${error}`);
        }
      }
    }

    useEffect(() => {
      if(capturedStudentData?.data?.data){
        uploadCapturedImage().then(imageId => {
          addAttendance({
            student: capturedStudentData?.data?.data?.id, 
            class: capturedStudentData?.data?.data?.attributes?.classes?.data[0]?.id ?? 0,
            attendance_date: new Date().toISOString().slice(0, 10),
            time: new Date().toISOString().slice(11, 19),
            present: true,
            on_leave: false,
            markedBy: userState?.user?.id ?? 0,
            capturedImage: imageId
          })
        });

      }
    },[capturedStudentData?.data?.data?.id])

    return {
        isLoading: isTrainedStudentsFetching || (isModelLoaded === false),
        videoRef,
        handleCloseLiveAttendance,
        handleCaptureLoop,
        capturedImage,
        switchCamera,
        devices,
        countdown,
        capturedImageBestMatch,
        toggleSidePanel,
        isSidePanelOpen,
        isMultipleFaceDetected,
        canvasRef,
        boxSize,
        isDaysAttendanceFetching,
        daysAttendance: daysAttendance?.data?.data ?? [],
        isCapturedStudentDataFetching,
        capturedStudentData: capturedStudentData?.data?.data,
        isMatching,
    }
}

export default LiveAttendanceViewModel