// Store constants here
export const DEFAULT_ERROR = 'Something Went Wrong';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_SECRET = 'AUTH_SECRET';
export const USER_STATE = 'USER_STATE';
export const USER_QUERY = 'USER_QUERY';
export const USER_ROLES_QUERY = 'USER_ROLES_QUERY';
export const SUPPLIER_QUERY = 'SUPPLIER_QUERY';
export const ACADEMY_QUERY = 'ACADEMY_QUERY';
export const STUDENTS_FITNESS_TESTS_LIST = 'STUDENTS_FITNESS_TESTS_LIST';
export const PARENT_LIST_QUERY = 'PARENT_LIST_QUERY';
export const ENQUIRY_QUERY = 'ENQUIRY_QUERY';
export const STUDENT_QUERY = 'STUDENT_QUERY';
export const ALL_APARTMENTS_QUERY = 'ALL_APARTMENTS_QUERY';
export const ALL_SCHOOLS_QUERY = 'ALL_SCHOOLS_QUERY';
export const ASSIGNMENT_TECHNIQUE_REQUEST_QUERY = 'ASSIGNMENT_TECHNIQUE_REQUEST_QUERY';
export const REGULAR_ASSIGNMENTS_LIST_QUERY = 'REGULAR_ASSIGNMENTS_LIST_QUERY';
export const STUDENT_HISTORY_QUERY = 'STUDENT_HISTORY_QUERY';
export const COACH_QUERY = 'COACH_QUERY';
export const USER_DETAILS_QUERY = 'USER_DETAILS_QUERY';
export const USER_DETAILS_LIST_QUERY = 'USER_DETAILS_LIST_QUERY';
export const STUDENT_DETAILS_QUERY = 'STUDENT_DETAILS_QUERY';
export const FEE_PAYMENT_QUERY = 'FEE_PAYMENT_QUERY';
export const STUDENTS_FEE_DETAILS_QUERY = 'STUDENTS_FEE_DETAILS_QUERY';
export const STUDENTS_FEE_RECORDS_QUERY = 'STUDENTS_FEE_RECORDS_QUERY';
export const STUDENTS_EVALUATION_RECORDS_QUERY = 'STUDENTS_EVALUATION_RECORDS_QUERY';
export const STUDENT_SUGGESTION_QUERY = 'STUDENT_SUGGESTION_QUERY';
export const STUDENT_SEARCH_SUGGESTION_QUERY = 'STUDENT_SEARCH_SUGGESTION_QUERY';
export const SCHEDULE_QUERY = 'SCHEDULE_QUERY';
export const SCHEDULE_DETAILS_QUERY = 'SCHEDULE_DETAILS_QUERY';
export const ASSIGNMENT_SUBMISSIONS_OF_STUDENT = 'ASSIGNMENT_SUBMISSIONS_OF_STUDENT';
export const AI_SCHEDULE_DETAILS_QUERY = 'AI_SCHEDULE_DETAILS_QUERY';
export const CLASS_DETAILS_QUERY = 'CLASS_DETAILS_QUERY';
export const DISCIPLINE_QUERY = 'DISCIPLINE_QUERY';
export const STUDENT_DATA_QUERY = 'STUDENT_DATA_QUERY';
export const CLASS_STUDENT_DETAILS = 'CLASS_STUDENT_DETAILS';
export const STUDENT_ENROLLMENT_FORM_QUERY = 'STUDENT_ENROLLMENT_FORM_QUERY';
export const STUDENT_ENROLMENT_DATA_QUERY = 'STUDENT_ENROLMENT_DATA_QUERY';
export const ANNOUNCEMENT_LIST_QUERY = 'ANNOUNCEMENT_LIST_QUERY';
export const FEE_STRUCTURES_LIST_DATA_QUERY = 'FEE_STRUCTURES_LIST_DATA_QUERY';
export const SUPPLIER_PAYMENT_DETAILS_QUERY = 'SUPPLIER_PAYMENT_DETAILS_QUERY';
export const FITNESS_TEST_DETAILS_LIST_QUERY = 'FITNESS_TEST_DETAILS_LIST_QUERY';
export const FITNESS_TEST_CONDUCTED_LIST_QUERY = 'FITNESS_TEST_CONDUCTED_LIST_QUERY';
export const FITNESS_TEST_CONDUCTING_DATA = 'FITNESS_TEST_CONDUCTING_DATA';
export const FITNESS_TEST_CONDUCTING_STUDENT_RECORDS = 'FITNESS_TEST_CONDUCTING_STUDENT_RECORDS';
export const FITNESS_TEST_VALUES_LIST = 'FITNESS_TEST_VALUES_LIST';
export const CERTIFICATIONS_LIST_FOR_ACADEMY_AND_DISCIPLINE = 'CERTIFICATIONS_LIST_FOR_ACADEMY_AND_DISCIPLINE';
export const CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE = 'CERTIFICATION_EVENTS_LIST_FOR_ACADEMY_AND_DISCIPLINE';
export const CERTIFICATION_STUDENT_RECORDS_LIST = 'CERTIFICATION_STUDENT_RECORDS_LIST';
export const STUDENT_CERTIFICATION_RECORDS_FOR_DISCIPLINE_LIST = 'STUDENT_CERTIFICATION_RECORDS_FOR_DISCIPLINE_LIST';
export const BLOGS_POST_QUERY = 'BLOGS_POST_QUERY';
export const BLOGS_LIST_QUERY = 'BLOGS_LIST_QUERY';
export const ACTIVE_VIRTUAL_MEETING_OF_USER = 'ACTIVE_VIRTUAL_MEETING_OF_USER';
export const VIRTUAL_CLASS_AUTH_TOKEN = 'VIRTUAL_CLASS_AUTH_TOKEN';
export const STUDENTS_TRAINED_FACE_DATA = 'STUDENTS_TRAINED_FACE_DATA';
export const LIVE_ATTENDANCE_OF_DAY_QUERY = 'LIVE_ATTENDANCE_OF_DAY_QUERY';
export const All_ACADEMY_QUERY = 'All_ACADEMY_QUERY';

