import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    ACTIVE_VIRTUAL_MEETING_OF_USER,
    COACH_QUERY,
    STUDENT_SUGGESTION_QUERY,
    SUPPLIER_QUERY,
    USER_DETAILS_QUERY,
    USER_QUERY,
} from '../../utils/constants/constants';
import { HeaderMenuService } from '../../services/header-menu-service';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    setUser,
    setUserDetails,
    setSupplierDetails,
    setWalkthroughFlags,
} from '../../utils/redux/user-slice';
import { User } from '../../models/user/user';
import { formatDateToInputDate, getJWTToken, setJWTToken } from '../../utils/helpers/helpers';
import { Routes } from '../navigation/routes';
import { useDebounce } from 'use-debounce';
import { RootState } from '../../utils/redux/store';
import { Roles } from '../../utils/auth/roles';
import { UserDetailsService } from '../../services/user-details-service';
import { UserDetailsData } from '../../models/user-details/user-details-data';
import { SupplierData } from '../../models/supplier/supplier-data';
import { UserPostData } from '../../models/user/user-post-data';
import ApiResponse from '../../utils/types/api-response';
import { UserService } from '../../services/user-services';
import Webcam from 'react-webcam';
import { VirtualMeetingService } from '../../services/virtual-meeting-service';
// import * as faceapi from 'face-api.js';

const HeaderMenuViewModel = () => {
    const navigate = useNavigate();
    const usedispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);

    const userState = useSelector((state: RootState) => state.user);
    const userRole = userState?.user?.role?.type;
    const queryClient = useQueryClient();
    const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
    
    const webcamRef = useRef<HTMLVideoElement>(null);
    const [image, setImage] = useState<string | null>(null);
    const [isModelLoaded, setIsModelLoaded] = useState(false);

    const {
        isSuccess: isSupplierFetchSuccess,
        isLoading: isSupplierDataLoading,
        data: supplierData,
    } = useQuery({
        queryKey: [SUPPLIER_QUERY],
        queryFn: HeaderMenuService.instance.getSupplierDetails.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: userRole === Roles.AUTHENTICATED_USER,
    });

    const {
        isSuccess: isUserDetailsDataFetchSuccess,
        isLoading: isUserDetailsDataLoading,
        data: userDetailsData,
    } = useQuery({
        queryKey: [USER_DETAILS_QUERY],
        queryFn: UserDetailsService.instance.getUserDetails.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: userRole === Roles.RESTRICTED_USER,
    });

    const {
        isLoading: isActiveVirtualMeetingOfUserFetching,
        data: activeVirtualMeetingOfUser,
    } = useQuery({
        queryKey: [ACTIVE_VIRTUAL_MEETING_OF_USER],
        queryFn: () => VirtualMeetingService.instance.getActiveMeetingOfUser(userState?.user?.id ?? 0),
        refetchOnWindowFocus: true,
        enabled: (userState?.user?.id ?? 0) > 0,
        refetchInterval: 30000,
    });

    useEffect(() => {
        if (isUserDetailsDataFetchSuccess && userDetailsData?.data?.data) {
            usedispatch(setUserDetails(userDetailsData?.data?.data[0] ?? ({} as UserDetailsData)));
        }
    }, [userDetailsData?.data?.data]);

    useEffect(() => {
        if (isSupplierFetchSuccess && supplierData?.data?.data) {
            usedispatch(setSupplierDetails(supplierData?.data?.data[0] ?? ({} as SupplierData)));
        }
    }, [supplierData?.data?.data]);

    // useEffect(() => {
    // const loadModels = async () => {
    //     // Replace with the path where your models are located
    //     const MODEL_URL = process.env.PUBLIC_URL + '/models';
    //     console.log(MODEL_URL);
        
    //     await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
    //     await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
    //     await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
    //     await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
    //     setIsModelLoaded(true);
    // };

    // loadModels();
    // }, []);

    useEffect(() => {
        if (isAttendanceOpen) {
            startWebcam();
        }
    }, [isAttendanceOpen])
    
        
    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (webcamRef.current) {
          webcamRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing the camera", err);
      }
    };

    const {
        isSuccess: isStudentSuggesionDataFetchSuccess,
        isLoading: isStudentDataFetchLoading,
        data: studentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SUGGESTION_QUERY, value],
        queryFn: HeaderMenuService.instance.getStudentList.bind(
            this,
            userState?.user?.id ?? 0,
            value
        ),
        refetchOnWindowFocus: false,
        enabled: searchTerm.length > 0,
    });

    const handleAttendance = () => {
        setIsAttendanceOpen(prevState => !prevState);
    }

    const handleOnMenuItemClick = useCallback((url: string) => {
        navigate(url);
        setIsSidebarOpen(false);
    }, []);

    const goToStudentDetails = useCallback(
        (studentId: number) => {
            navigate(Routes.Students + '/' + studentId);
        },
        [navigate]
    );

    const handlesearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event?.target?.value ?? '');
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

    const handleToggleMenuClick = () => {
        setIsSidebarOpen(prev => !prev);
    };

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isWebDropdownOpen, setWebDropdownOpen] = useState(false);

    const handleAvatarClick = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleSettingsIconClick = () => {
        setWebDropdownOpen(prev => !prev);
    };

    const {
        isSuccess: isUserInfoFetchSuccess,
        data: userData,
        isLoading: isUserInfoFetching,
    } = useQuery({
        queryKey: [USER_QUERY],
        queryFn: HeaderMenuService.instance.getUserInfo,
        refetchOnWindowFocus: false,
        enabled: getJWTToken() != null && getJWTToken().length > 0,
    });

    useEffect(() => {
        if (isUserInfoFetchSuccess && getJWTToken() !== '') {
            if (userData.success) {
                usedispatch(setUser(userData?.data ?? ({} as User)));
                usedispatch(setWalkthroughFlags(userData.data?.tutorialFlag ?? ''));
            } else {
                if (userData.error?.status === 403) {
                    setJWTToken('');
                }
            }
        }
    }, [isUserInfoFetchSuccess, userData]);

    const setUserTutorialFlag = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(data, userState?.user?.id ?? 0);
        if (response.success) {
            return response;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const { mutate: updateUserTutorialFlag } = useMutation(setUserTutorialFlag, {
        onSuccess: data => {
            queryClient.invalidateQueries(USER_QUERY);
        },
        onError: error => {},
    });

    useEffect(() => {
        if (!(userState.walkthroughFlag === 'FETCHING')) {
            updateUserTutorialFlag({ tutorialFlag: userState.walkthroughFlag });
        }
    }, [userState.walkthroughFlag]);
    
// const capturePhoto = () => {
//     if (webcamRef.current && isModelLoaded) {
//         const canvas = faceapi.createCanvasFromMedia(webcamRef.current);
//         canvas.className="w-full h-full top-0 left-0"
        
//         const videoContainer = webcamRef.current.parentNode as HTMLElement | null;

//         if (videoContainer) {
//             videoContainer.append(canvas);

//             const displaySize = {
//                 width: webcamRef.current.videoWidth,
//                 height: webcamRef.current.videoHeight,
//             };
//             faceapi.matchDimensions(canvas, displaySize);
//             canvas?.toBlob(async (blob) => {
//                 if (blob) {
//                 const data = new FormData();
//                 const fileName = `camera-capture-${Date.now()}.jpg`;
//                 data.append("files", blob as File, fileName);
//                 const response = await UploadService.instance.uploadImage(data);
//                     if (response?.success && response?.data) {
//                     console.log(response?.data);
//                     } else {
//                         throw new Error('error uploading image');
//                     }
//                }
               
//            }, 'image/jpeg');
//             setInterval(async () => {
//                 if (webcamRef.current && isModelLoaded) {
//                     const detections = await faceapi.detectAllFaces(
//                         webcamRef.current,
//                         new faceapi.TinyFaceDetectorOptions()
//                     ).withFaceLandmarks().withFaceExpressions();

//                     const resizedDetections = faceapi.resizeResults(detections, displaySize);

//                     // Check if context is not null
//                     const context = canvas.getContext('2d');
//                     if (context) {
//                         context.clearRect(0, 0, canvas.width, canvas.height);
                        
//                         // Draw the detections
//                         faceapi.draw.drawDetections(canvas, resizedDetections);
//                         faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
//                         faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
//                     } else {
//                         console.error("Failed to get 2D context from canvas.");
//                     }
//                 }
//             }, 100);
//         } else {
//             console.error("Video container is null or undefined.");
//         }
//     }
// };


    return {
        isSupplierFetchSuccess,
        supplierData,
        activeMeeting: (activeVirtualMeetingOfUser?.data?.data && activeVirtualMeetingOfUser?.data?.data.length > 0) ? activeVirtualMeetingOfUser?.data?.data[0] : null,
        userData: userState?.user,
        handleOnMenuItemClick,
        handlesearchInputChange,
        isStudentSuggesionDataFetchSuccess,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        searchTerm,
        goToStudentDetails,
        isSidebarOpen,
        handleToggleMenuClick,
        isDropdownOpen,
        handleAvatarClick,
        userRole,
        isUserDetailsDataFetchSuccess,
        userDetailsData,
        isWebDropdownOpen,
        handleSettingsIconClick,
        isSupplierDataLoading,
        isUserDetailsDataLoading,
        userState,
        webcamRef,
        isAttendanceOpen,
        handleAttendance,
        // capturePhoto,
        image,
    };
};

export default HeaderMenuViewModel;
