import React, { useMemo, useState } from 'react';
import NavMenu from '../../components/widgets/nav-menu';
import style from './style.module.css';
import StudentsViewModel from '../../view-models/students-view-model';
import arrowDropdownIcon from '../../../assets/icons/arrow-dropdown.png';
import filterIcon from '../../../assets/icons/filter.png';
import arrowLeftIcon from '../../../assets/icons/chevron-left.png';
import arrowRightIcon from '../../../assets/icons/chevron-right.png';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import FilterCheckBoxItem from '../../components/pieces/filter-check-box-item';
import { AiOutlinePlus } from 'react-icons/ai';
import EnquiresViewModel from '../../view-models/enquiries-view-model';
import NewEnquiryForm from '../../components/widgets/new-enquiry-form';
import EnquiryListItem from '../../components/pieces/enquiry-list-item';
import NewStudentForm from '../../components/widgets/new-student-form';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { Roles } from '../../../utils/auth/roles';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import AddStudent from '../../actions/add-student';

const Enquires = () => {
    const {
        isfilterModalOpen,
        handleFilterModalOpen,
        handleFilterModalClose,
        isNewEnquiryModalOpen,
        handleNewEnquiryModalClose,
        handleAddEnquiryBtnClick,
        mapAcademiesToOptions,
        mapDiciplinesOfSelectedAcademiesToOptions,
        handleEditEnquiryModalClose,
        mapClassesOfSelectedDisciplinesToOptions,
        handleInputChange,
        enquiryFormData,
        handleAddNewEnquiry,
        enquiriesData,
        handleEditEnquiry,
        handleEditBtnClick,
        handleNotInterestedClick,
        expandedEnquiryItem,
        handleEnquiryItemExpandCollapse,
        enquiryAcademyListExpanded,
        enquiryDisciplineListExpanded,
        enquiryClassListExpanded,
        handleEnquiryInfoListExpandClick,
        handleNewStudentModalClose,
        isNewStudentModalOpen,
        isEditEnquiryModalOpen,
        switchTab,
        handleStudentInputChange,
        studentFormData,
        activeTab,
        mapClassesToOptions,
        handleProfileImgChange,
        isProfileImagePosting,
        profileImageInputKey,
        handleAddNewStudent,
        handleAddNewStudentBtnClick,
        handleAddRemarks,
        remarksText,
        handleRetRemarksTextChange,
        state,
        dispatch,
        isEnquiriesFetchSuccess,
        itemsPerPage,
        indexOfLastItem,
        indexOfFirstItem,
        currentPage,
        total,
        isSortOptionsVisible,
        handleSortDropBtnClick,
        selectedSortOption,
        handleSortOptionChange,
        visibleFilter,
        toggleVisibleFilter,
        selectedStatusForFilter,
        onStatusFilterSelect,
        handleEnquiryListItemCheck,
        handleAllListSelect,
        allListItemsSelected,
        sendWhatsappMessage,
        isSendEmailMessageDisabled,
        sendEmail,
        isNewEnquiryAdding,
        isEnquiryEditing,
        isNewStudentAdding,
        userState,
        sortDropdownRef,
        filterDropdownRef,
        currentEnquiryData,
        isEnquiryUpdating,
    } = EnquiresViewModel();

    const renderFilters = useMemo(() => {
        return (
            <div ref={sortDropdownRef}>
                <div ref={filterDropdownRef}>
                    <div className={style.filterLabelContentWrapper}>
                        <div
                            className={`${style.filterName} ${selectedStatusForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                            onClick={() => toggleVisibleFilter(1)}
                        >
                            Enquiry Status
                        </div>
                        {visibleFilter === 1 && (
                            <div className={style.filterItemsContainer}>
                                {['Enquiring', 'Added Student', 'Not Interested'].map(
                                    (status, index) => (
                                        <FilterCheckBoxItem
                                            key={index}
                                            filterName={status}
                                            isChecked={selectedStatusForFilter.includes(status)}
                                            onFilterSelected={() => onStatusFilterSelect(status)}
                                        />
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }, [visibleFilter, selectedStatusForFilter]);

    return (
        <NavMenu>
            {(isNewEnquiryAdding ||
                isEnquiryEditing ||
                isNewStudentAdding ||
                isEnquiryUpdating) && <FullScreenLoader />}
            {userState?.userDetails?.attributes?.enquiriesAccess !== 'noAccess' ||
            userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                <div className={style.studentsContainer}>
                    {/* Enquiries List Container */}
                    <div className={style.studentsContentContainer}>
                        <div className="flex items-center justify-center h-16 w-36 shadow-xl rounded-md p-4 transition-all cursor-pointer bg-white border-2 border-gray-500">
                            <div className={style.studentsLabel}>Enquiries</div>
                        </div>
                        <div className={style.studentsAddButtonContainer}>
                            {userState?.userDetails?.attributes?.enquiriesAccess !==
                                'restrictedView' && (
                                <button
                                    className={style.studentsAddButton}
                                    onClick={handleAddEnquiryBtnClick}
                                >
                                    <AiOutlinePlus /> Add new enquiry
                                </button>
                            )}
                        </div>
                    </div>

                    <div className={style.studentsLeftPanelContainer}>
                        {/* Enquiries Filter Container */}
                        <div className={style.studentsFilterContainer}>
                            <div className={style.studentsFilterTitle}>
                                <div className={style.filterTitle}>
                                    Filters
                                    <img
                                        className={style.filterIcon}
                                        src={filterIcon}
                                        alt="filter"
                                    />
                                </div>
                                {/* {state.academies.length > 0 && renderFilters} */}
                                {renderFilters}
                            </div>
                        </div>

                        {isEnquiriesFetchSuccess ? (
                            <div className={style.contentContainerMain}>
                                <div className={`${style.studentsHeader} relative`}>
                                    <div className="flex sm:gap-4 gap-8 items-center w-full ">
                                        <input
                                            type="checkbox"
                                            onChange={handleAllListSelect}
                                            checked={allListItemsSelected}
                                        />
                                        <span className="text-xs">Select All</span>
                                        {userState?.userDetails?.attributes?.enquiriesAccess !==
                                            'restrictedView' && (
                                            <>
                                                <button
                                                    id="sendEmailButton"
                                                    onClick={sendEmail}
                                                    className={style.primaryBtn}
                                                    disabled={isSendEmailMessageDisabled}
                                                >
                                                    Send Email
                                                </button>

                                                <button
                                                    id="sendWhatsappButton"
                                                    onClick={sendWhatsappMessage}
                                                    className={style.outlineBtn}
                                                    disabled={isSendEmailMessageDisabled}
                                                >
                                                    Whatsapp
                                                </button>
                                            </>
                                        )}

                                        <div className={style.studentsLabel}>
                                            Total enquiries: {total}
                                        </div>
                                        {/* mobile responsive div */}
                                        <div className="flex-grow flex justify-end">
                                            <img
                                                onClick={handleFilterModalOpen}
                                                src={filterIcon}
                                                className="sm:hidden w-5 h-5 cursor-pointer"
                                                alt="filter"
                                            />
                                        </div>
                                        <div
                                            ref={sortDropdownRef}
                                            className="relative"
                                        >
                                            <div
                                                className={`${style.sortDropdownBtn}`}
                                                onClick={handleSortDropBtnClick}
                                            >
                                                Sort by
                                                <img
                                                    className={style.dropDownIcon}
                                                    src={arrowDropdownIcon}
                                                    alt="downarrow"
                                                />
                                            </div>
                                            {isSortOptionsVisible && (
                                                <div className="absolute flex flex-col p-2 gap-2 bg-white z-50 right-0 w-[200%] shadow-md shadow-gray-300">
                                                    <div className="flex gap-2 items-center">
                                                        <input
                                                            type="checkbox"
                                                            name="sort"
                                                            checked={selectedSortOption === 1}
                                                            onChange={() =>
                                                                handleSortOptionChange(1)
                                                            }
                                                        />
                                                        <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                                            Enquiry date (newest)
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <input
                                                            type="checkbox"
                                                            name="sort"
                                                            checked={selectedSortOption === 2}
                                                            onChange={() =>
                                                                handleSortOptionChange(2)
                                                            }
                                                        />
                                                        <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                                            Enquiry Date (oldest)
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {userState?.userDetails?.attributes?.enquiriesAccess !==
                                    'addOnly' && (
                                    <div className={style.studentsListItemContainer}>
                                        {enquiriesData?.map((item, index) => (
                                            <EnquiryListItem
                                                key={index}
                                                enquiryData={item}
                                                handleNotInterestedClick={() =>
                                                    handleNotInterestedClick(item)
                                                }
                                                isExpanded={expandedEnquiryItem === item.id}
                                                handleEnquiryItemExpandCollapse={() =>
                                                    handleEnquiryItemExpandCollapse(item.id)
                                                }
                                                handleEnquiryInfoListExpandClick={
                                                    handleEnquiryInfoListExpandClick
                                                }
                                                enquiryAcademyListExpanded={
                                                    enquiryAcademyListExpanded === item.id
                                                }
                                                enquiryDisciplineListExpanded={
                                                    enquiryDisciplineListExpanded === item.id
                                                }
                                                enquiryClassListExpanded={
                                                    enquiryClassListExpanded === item.id
                                                }
                                                handleAddNewStudentBtnClick={() =>
                                                    handleAddNewStudentBtnClick(item)
                                                }
                                                handleAddRemarks={() => handleAddRemarks(item)}
                                                remarksText={remarksText}
                                                handleRetRemarksTextChange={
                                                    handleRetRemarksTextChange
                                                }
                                                handleEnquiryListItemCheck={() =>
                                                    handleEnquiryListItemCheck(item)
                                                }
                                                isAllChecked={allListItemsSelected}
                                                isRestricted={
                                                    userState?.userDetails?.attributes
                                                        ?.enquiriesAccess === 'restrictedView'
                                                }
                                                handleEditBtnClick={() => handleEditBtnClick(item)}
                                            />
                                        ))}
                                    </div>
                                )}

                                <div className={style.studentsFooterContainer}>
                                    <div className={style.studentsRecordsLabel}>
                                        {indexOfFirstItem + 1}-{indexOfLastItem} of{' '}
                                        {enquiriesData?.length ?? 0} records
                                    </div>
                                    <div className={style.studentsRecordsDropdownContainerWrapper}>
                                        <div>Show</div>
                                        <div className={style.studentsRecordsDropdownContainer}>
                                            10{' '}
                                            <img
                                                className={style.studentsArrowDropdownIcon}
                                                src={arrowDropdownIcon}
                                                alt="down"
                                            />
                                        </div>
                                    </div>
                                    <div className={style.studentsPaginationContainer}>
                                        <img
                                            className={style.studentsPaginationIcon}
                                            src={arrowLeftIcon}
                                            alt="arrowLeftIcon"
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload: currentPage == 1 ? 1 : currentPage - 1,
                                                })
                                            }
                                        />
                                        {Array.from({
                                            length: Math.ceil(total / itemsPerPage),
                                        }).map((_, index) => (
                                            <div
                                                className={`cursor-pointer ${
                                                    index + 1 == currentPage
                                                        ? 'text-black'
                                                        : 'text-blue-500'
                                                }`}
                                                key={index}
                                                onClick={() =>
                                                    dispatch({
                                                        type: 'setCurrentPage',
                                                        payload: index + 1,
                                                    })
                                                }
                                            >
                                                {index + 1}
                                            </div>
                                        ))}
                                        <img
                                            className={style.studentsPaginationIcon}
                                            src={arrowRightIcon}
                                            alt="arrowRightIcon"
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload:
                                                        currentPage + itemsPerPage + 1 > total
                                                            ? currentPage
                                                            : currentPage + 1,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <AccessDeniedPage />
            )}

            <PopupModal
                handleModalClose={handleNewEnquiryModalClose}
                isOpen={isNewEnquiryModalOpen}
                background="white"
                title="Add Enquiry"
            >
                <NewEnquiryForm
                    mapAcademiesToOptions={mapAcademiesToOptions}
                    mapDiciplinesOfSelectedAcademiesToOptions={
                        mapDiciplinesOfSelectedAcademiesToOptions
                    }
                    mapClassesOfSelectedDisciplinesToOptions={
                        mapClassesOfSelectedDisciplinesToOptions
                    }
                    handleInputChange={handleInputChange}
                    enquiryFormData={enquiryFormData}
                    handleEnquiryFormSubmit={handleAddNewEnquiry}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleEditEnquiryModalClose}
                isOpen={isEditEnquiryModalOpen}
                background="white"
                title="Edit Enquiry"
            >
                <NewEnquiryForm
                    mapAcademiesToOptions={mapAcademiesToOptions}
                    isEdit={true}
                    mapDiciplinesOfSelectedAcademiesToOptions={
                        mapDiciplinesOfSelectedAcademiesToOptions
                    }
                    mapClassesOfSelectedDisciplinesToOptions={
                        mapClassesOfSelectedDisciplinesToOptions
                    }
                    handleInputChange={handleInputChange}
                    enquiryFormData={enquiryFormData}
                    handleEnquiryFormSubmit={handleEditEnquiry}
                />
            </PopupModal>

            {/* <PopupModal
                handleModalClose={handleNewStudentModalClose}
                isOpen={isNewStudentModalOpen}
                background="white"
                title="Add Student">
                <NewStudentForm
                    switchTab={switchTab}
                    handleInputChange={handleStudentInputChange}
                    handleAddNewStudent={handleAddNewStudent}
                    formData={studentFormData}
                    activeTab={activeTab}
                    mapAcademiesToOptions={mapAcademiesToOptions}
                    mapClassesToOptions={mapClassesToOptions}
                    isEditMode={false}
                    handleProfileImgChange={handleProfileImgChange}
                    isProfileImagePosting={isProfileImagePosting}
                    profileImageInputKey={profileImageInputKey}
                    isExpandedForAddAcademy={isExpandedForAddAcademy}
                    handleExpandClickForAddAcademy={handleExpandClickForAddAcademy}
                    academyCards={academyCards}
                    handleAddButtonClick={handleAddNewAcademyCardButtonClick}
                    handleDeleteClickForAcademy={handleDeleteClickForAcademy}
                    mapRemainingAcademiesToOptions={mapRemainingAcademiesToOption}
                    handleAddAcademyInputChange={handleNewAcademyCardInputChange}
                    mapRemainingClassesToOption={mapRemainingClassesToOption}
                    
                />
            </PopupModal> */}
            <AddStudent
                isOpen={isNewStudentModalOpen}
                handleClose={handleNewStudentModalClose}
                isEdit={false}
                enquiryData={currentEnquiryData}
            />

            <PopupModal
                handleModalClose={handleFilterModalClose}
                isOpen={isfilterModalOpen}
                background="white"
            >
                <div className="w-[80vw] h-[80vh] p-2">
                    <div className="p-4 border rounded">
                        <div className={style.filterTitle}>
                            Filters
                            <img
                                className={style.filterIcon}
                                src={filterIcon}
                                alt="filter"
                            />
                        </div>
                        {renderFilters}
                    </div>
                </div>
            </PopupModal>
        </NavMenu>
    );
};

export default Enquires;
