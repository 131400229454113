import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BlogService } from '../../services/blog-services';
import { useQuery, useQueryClient } from 'react-query';
import { RootState } from '../../utils/redux/store';
import { useSelector } from 'react-redux';
import { Comment, CommentReply, DeleteCommentData, EditedComment } from '../../models/blog/blog-attributes';
import { BLOGS_POST_QUERY } from '../../utils/constants/constants';

const BlogPageViewModel = () => {
    const { blogId } = useParams();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [isLiked, setIsLiked] = useState(() => {
        const storedLiked = localStorage.getItem(`isLiked-${blogId}`);
        return storedLiked ? JSON.parse(storedLiked) : false;
    });
    
    const [isDisliked, setIsDisliked] = useState(() => {
        const storedDisliked = localStorage.getItem(`isDisliked-${blogId}`);
        return storedDisliked ? JSON.parse(storedDisliked) : false;
    });

    // Update localStorage whenever the state changes
    useEffect(() => {
        localStorage.setItem(`isLiked-${blogId}`, JSON.stringify(isLiked));
    }, [isLiked]);

    useEffect(() => {
        localStorage.setItem(`isDisliked-${blogId}`, JSON.stringify(isDisliked));
    }, [isDisliked]);

    const handleLike = async() => {
        if(isLiked){
            await BlogService.instance.undoBlogLike(Number(blogId) ?? 0)
        }else{
            await BlogService.instance.blogLike(Number(blogId) ?? 0)
        }
        if (isDisliked){
            await BlogService.instance.undoBlogDislike(Number(blogId) ?? 0)
            setIsDisliked(false);
        }
        setIsLiked(!isLiked);
    };

    const handleDislike = async() => {
        if(isDisliked){
            await BlogService.instance.undoBlogDislike(Number(blogId) ?? 0)
        }else{
            await BlogService.instance.blogDislike(Number(blogId) ?? 0)
        }
        if (isLiked) {
            await BlogService.instance.undoBlogLike(Number(blogId) ?? 0)
            setIsLiked(false);
        }
        setIsDisliked(!isDisliked);
    };

    const [blogComments, setBlogComments] = useState<Comment[]>([])

    const {
        isLoading: isBlogPostFetching,
        data: blogPost,
    } = useQuery({
        queryKey: [BLOGS_POST_QUERY, blogId],
        queryFn: () => BlogService.instance.getBlogPost(Number(blogId)),
        refetchOnWindowFocus: false,
        enabled: Number(blogId) > 0
    });
    
    useEffect(() => {
        if(blogPost?.data?.data?.attributes?.comments){
            setBlogComments(blogPost?.data?.data?.attributes?.comments)
        }
    },[blogPost?.data?.data?.attributes?.comments])

    const handleAddNewComment = async(data: Comment) => {
        await BlogService.instance.postBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleAddNewCommentReply = async(data: CommentReply) => {
        await BlogService.instance.postBlogCommentReply(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleEditComment = async(data: EditedComment) => {
        await BlogService.instance.updateBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteComment = async(data: DeleteCommentData) => {
        await BlogService.instance.deleteBlogComment(Number(blogId),data)
            .then(res => {
                queryClient.invalidateQueries(BLOGS_POST_QUERY)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return {
        isLoading: isBlogPostFetching,
        blogPost: blogPost?.data?.data,
        user,
        navigate,
        isLiked,
        isDisliked,
        handleLike,
        handleDislike,
        blogComments,
        handleAddNewComment,
        handleAddNewCommentReply,
        handleEditComment,
        handleDeleteComment,
    }
}

export default BlogPageViewModel