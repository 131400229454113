import React from 'react';
import style from './style.module.css';
import PhotoUploadInput from '../photo-upload-input';
import binIcon from '../../../../assets/icons/delete-bin.png';
import Select from 'react-select';
import { AddAcademyFormValues } from '../../../view-models/academies-view-model';
import { Label } from 'recharts';
import Loader from '../../bits/loader';
import redClose from '../../../../assets/icons/red-close.png';

interface AddAcademyProps {
    // images: File[] | null;
    handlerSubmitForm: (e: React.FormEvent) => void;
    addAcademyformValues: AddAcademyFormValues;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    uploadedImageData?: string[];
    handleDeleteImage?: (index: number) => void;
    // isUploading: boolean;
    // setIsUploading: (isUploading : boolean) =>void;
    handleAddAcademyFormInputChange: (fieldName: string, value: string,checked?:boolean) => void;
    mapdisciplinestoOptions: () => { value: number; label: string }[];
    handleAddBankName: () => void;
    deleteExistingBankAccount: (index: number) => void;
    isEditMode: boolean;
    isAcademyImagePosting: boolean;
    ammenities: { value: number, label: string }[],
    handleLogoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    uploadedLogoData: string;
    isAcademyLogoPosting: boolean
    handleDeleteLogo?: () => void;
}

function Addacademy({
    handleFileChange,
    uploadedImageData,
    handleDeleteImage,
    addAcademyformValues,
    // addAcademyFormData,
    //  isUploading,
    //  setIsUploading,
    handleAddAcademyFormInputChange,
    handlerSubmitForm,
    mapdisciplinestoOptions,
    handleAddBankName,
    deleteExistingBankAccount,
    isEditMode,
    isAcademyImagePosting,
    handleLogoChange,
    uploadedLogoData,
    isAcademyLogoPosting,
    handleDeleteLogo
}: AddAcademyProps) {
    const logoData: any = uploadedLogoData
    
    return (
        <div className="w-[500px] pr-5 px-3 bg-white py-4">
            <div className={style.academiesModalContainer}>
                <form
                    onSubmit={handlerSubmitForm}
                    className={style.academiesModal}
                >
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>
                                <span className={style.requiredFieldLabelPrompt}>*</span>
                                Academy:
                            </label>
                            <input
                                type="text"
                                required
                                placeholder="Enter your academy's name"
                                className={style.formInput}
                                value={addAcademyformValues.adacemyName}
                                onChange={e => {
                                    handleAddAcademyFormInputChange('adacemyName', e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>Address:</label>
                            <input
                                type="text"
                                placeholder="Enter your academy's Address"
                                className={style.formInput}
                                value={addAcademyformValues.academyAddress}
                                onChange={e => {
                                    handleAddAcademyFormInputChange(
                                        'academyAddress',
                                        e.target.value
                                    );
                                }}
                            />
                        </div>
                        {/* PINCODE */}
                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>Pincode:</label>
                            <input
                                type="number"
                                placeholder="Postal / Zip Code"
                                className={style.formInput}
                                value={addAcademyformValues.pinCode}
                                onChange={e => {
                                    handleAddAcademyFormInputChange(
                                        'pinCode',
                                        e.target.value
                                    );
                                }}
                            />
                        </div>
                        {/* DISCIPLINES */}
                        <div>
                            <div className={style.formInputLabel}>
                                <span className={style.requiredFieldLabelPrompt}>*</span>
                                Disciplines:
                            </div>

                            <Select
                                isMulti
                                options={mapdisciplinestoOptions()}
                                required
                                className={style.formInput}
                                classNamePrefix="Select disciplines"
                                onChange={item =>
                                    handleAddAcademyFormInputChange(
                                        'selectedDisciplines',
                                        item.map(({ value }) => value).join(',')
                                    )
                                }
                                value={mapdisciplinestoOptions().filter(option =>
                                    addAcademyformValues.selectedDisciplines.includes(option.value)
                                )}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>Contact number:</label>
                            <input
                                type="number"
                                placeholder="Enter your academy's contact number"
                                className={style.formInput}
                                value={addAcademyformValues.contactnumber}
                                onChange={e => {
                                    handleAddAcademyFormInputChange(
                                        'contactnumber',
                                        e.target.value
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>Email ID:</label>
                            <input
                                type="email"
                                placeholder="Enter your academy's contact number"
                                className={style.formInput}
                                value={addAcademyformValues.emailid}
                                onChange={e => {
                                    handleAddAcademyFormInputChange('emailid', e.target.value);
                                }}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className={style.formInputLabel}>Webpage :</label>
                            <input
                                type="text"
                                placeholder="Enter your academy's website URL"
                                className={style.formInput}
                                value={addAcademyformValues.webpage}
                                onChange={e => {
                                    handleAddAcademyFormInputChange('webpage', e.target.value);
                                }}
                            />
                        </div>

                        {/* Ammenities */}
                        <div className='flex flex-col'>
                            <label className={style.formInputLabel}>Ammenities :</label>
                            <div className='flex flex-wrap w-full gap-4'>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                    type="checkbox"
                                    value="Certified coach"
                                    name='ammenities'
                                    onChange={e => {
                                        handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }}
                                    checked={addAcademyformValues?.ammenities?.includes('Certified coach')}
                                    />
                                    <span className='text-sm'>Certified coach</span>
                                </div>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                        type="checkbox"
                                        value="Restroom"
                                        name='ammenities'
                                        onChange={e => {
                                            handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }}
                                        checked={addAcademyformValues?.ammenities?.includes('Restroom')}
                                    />
                                        
                                    <span className='text-sm'>Restroom</span>
                                </div>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                        type="checkbox"
                                        value="Parking"
                                        name='ammenities'
                                        checked={addAcademyformValues?.ammenities?.includes('Parking')}
                                        onChange={e => {
                                            handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }} />
                                    <span className='text-sm'>Parking</span>
                                </div>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                        type="checkbox"
                                        value="CCTV"
                                        name='ammenities'
                                        checked={addAcademyformValues?.ammenities?.includes('CCTV')}
                                        onChange={e => {
                                            handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }} />
                                    <span className='text-sm'>CCTV</span>
                                </div>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                        type="checkbox"
                                        value="Waiting-room"
                                        name='ammenities'
                                        checked={addAcademyformValues?.ammenities?.includes('Waiting-room')}
                                        onChange={e => {
                                            handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }} />
                                    <span className='text-sm'>Waiting-area</span>
                                </div>
                                <div className="flex items-center gap-4 w-40">
                                    <input
                                        type="checkbox"
                                        value="Drinking water"
                                        name='ammenities'
                                        checked={addAcademyformValues?.ammenities?.includes('Drinking water')}
                                        onChange={e => {
                                            handleAddAcademyFormInputChange('ammenities', e.target.value, e.target.checked);
                                        }} />
                                    <span className='text-sm'>Drinking-water</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Logo */}
                    <div className='flex flex-col gap-2.5'>
                    <div className="text-[14px] leading-[22px] tracking-normal text-black mt-2 font-semibold">
                        Academy Logo<span className="text-sm pl-2 text-gray-400 font-normal">(max 10MB)</span>
                    </div>

                    <div className="flex flex-wrap gap-1">
                        <div className="relative flex flex-wrap gap-2">
                            {/* Container for the upload button */}
                            <div className="relative inline-block overflow-hidden h-[105px] w-[105px] bg-[#f7f8f9] border border-[#b2b2b2] ">
                                <label htmlFor="logoInput" className="cursor-pointer">
                                    <input
                                        type="file"
                                        id="logoInput"
                                        className="absolute inset-0 opacity-0 w-full h-full"
                                        onChange={handleLogoChange}
                                        accept="image/*"
                                    />
                    {logoData?.data == null && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center z-10 text-[#bfbfbf]">
                            <div>+</div>
                            <div>Upload</div>
                        </div>
                    )}
                        <div className="absolute inset-0 flex items-center justify-center z-20 border border-white">
                            {(isAcademyLogoPosting) && (
                            <div className="min-h-[105px] h-[105px] w-[105px] text-center min-w-[105px] border flex justify-center items-center text-gray-500 z-30">
                                <Loader />
                            </div>
                            )}
                            {(!isAcademyLogoPosting && uploadedLogoData) && <img
                                className="w-full h-full object-cover"
                                src={uploadedLogoData}
                                alt="Upload Logo"
                            />}
                            {uploadedLogoData && <button
                                className="absolute top-0 right-0"
                                onClick={event => {
                                    event.preventDefault();
                                    handleDeleteLogo && handleDeleteLogo();
                                }}
                            >
                                <img
                                    src={redClose}  
                                    alt="Delete"
                                />
                            </button>}
                        </div>
                </label>
            </div>
        </div>
    </div>
</div>


                    <PhotoUploadInput
                        label="Academy photos :"
                        uploadedImageData={uploadedImageData}
                        handleFileChange={handleFileChange}
                        handleDeleteImage={handleDeleteImage}
                        isAcademyImagePosting={isAcademyImagePosting}
                    />

                    <div className="flex flex-col">
                        <label className={style.formInputLabel}>GST No:</label>
                        <input
                            type="text"
                            placeholder="Enter organisation's GST number"
                            className={style.formInput}
                            value={addAcademyformValues.GSTNo}
                            onChange={e => {
                                handleAddAcademyFormInputChange('GSTNo', e.target.value);
                            }}
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className={style.formInputLabel}>Associated Bank Accounts:</label>
                        {addAcademyformValues?.bankAccounts?.map((acc, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-center border border-gray-500 border-opacity-30 rounded bg-slate-100"
                            >
                                <input
                                    type="text"
                                    className="text-sm text-gray-500 resize-none border-none w-full disabled:bg-slate-100"
                                    value={acc}
                                    disabled
                                />
                                <div
                                    className="px-2 text-blue-600 cursor-pointer"
                                    onClick={() => deleteExistingBankAccount(index)}
                                >
                                    <img
                                        src={binIcon}
                                        className="h-6 w-6"
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="flex">
                            <input
                                type="text"
                                placeholder="Enter Bank account name"
                                className={style.formInput}
                                value={addAcademyformValues.bankAccName}
                                onChange={e => {
                                    handleAddAcademyFormInputChange('bankAccName', e.target.value);
                                }}
                            />
                            <div
                                className="flex items-center justify-center px-2 border-2 rounded text-3xl text-slate-400 cursor-pointer"
                                onClick={handleAddBankName}
                            >
                                +
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <label className={style.formInputLabel}>Remarks:</label>
                        <input
                            type="text"
                            placeholder="Enter remarks about academy"
                            className={style.formInput}
                            value={addAcademyformValues.remarks}
                            onChange={e => {
                                handleAddAcademyFormInputChange('remarks', e.target.value);
                            }}
                        />
                    </div>

                    <div className="flex justify-end pt-3  ">
                        <button
                            className="border rounded bg-[#455a64] text-white text-sm font-normal leading-[22px] tracking-normal text-center h-8 px-[15px] py-1 border-solid border-[#455A64]"
                            type="submit"
                        >
                            {isEditMode ? 'Update Academy' : 'Save Academy'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Addacademy;
