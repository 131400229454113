import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Routes } from '../routes';
import Dashboard from '../../pages/dashboard';
import Login from '../../pages/login';
import Academies from '../../pages/academies';
import Students from '../../pages/students';
import StudentDetails from '../../pages/student-details';
import FeePayment from '../../pages/fee-payment';
import ScheduleDetails from '../../pages/schedule-details';
import Schedule from '../../pages/schedule';
import ResetPassword from '../../pages/reset-password';
import LandingPage from '../../pages/landing-page';
import { AuthWrapper } from '../../../utils/auth/auth-wrapper';
import Enquires from '../../pages/enquiries';
import UserManagement from '../../pages/users';
import Settings from '../../pages/settings';
import ProgressTracker from '../../pages/progress-tracker';
import EnrollStudentFormPage from '../../pages/student-enroll-form';
import Announcement from '../../pages/announcement';
import StudentEvalResults from '../../pages/studentEvalResults';
import ErrorPage from '../../pages/error-page';
import BlogPage from '../../pages/blog-page';
import BlogsListPage from '../../pages/blogs-list-page';
import VirtualClass from '../../pages/virtual-class';
import LiveAttendance from '../../pages/live-attendance';

export const router = createBrowserRouter([
    {
        path: Routes.LandingPage,
        element: <LandingPage />,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Login,
        element: <Login />,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.ResetPassword,
        element: <ResetPassword />,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Dashboard,
        element: <AuthWrapper><Dashboard /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.ProgressTracker,
        element: <AuthWrapper><ProgressTracker /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Settings,
        element: <AuthWrapper><Settings /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Academies,
        element: <AuthWrapper><Academies /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Enquires,
        element: <AuthWrapper><Enquires /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Students,
        element: <AuthWrapper><Students /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.UserManagement,
        element: <AuthWrapper><UserManagement /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Students + '/:id',
        element: <AuthWrapper><StudentDetails /></AuthWrapper>,
        errorElement: <ErrorPage/>,
    },
    {
        path: Routes.Students + '/:studentId' + '/:techniqueId',
        element: <AuthWrapper><StudentEvalResults /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.FeePayment,
        element: <AuthWrapper><FeePayment /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.ScheduleDetails + '/:id',
        element: <AuthWrapper><ScheduleDetails /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.Schedule,
        element: <AuthWrapper><Schedule /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.NotFound,
        element: <Login />,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.EnrollStudentFrom + '/:slug',
        element: <EnrollStudentFormPage />,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.Announcement,
        element: <AuthWrapper><Announcement /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.VirtualClass,
        element: <AuthWrapper><VirtualClass /></AuthWrapper>,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.Blog + '/:blogId',
        element: <BlogPage />,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.BlogsList,
        element: <BlogsListPage />,
        errorElement: <ErrorPage/>
    },
    {
        path: Routes.LiveAttendance,
        element: <AuthWrapper><LiveAttendance /></AuthWrapper>,
        errorElement: <ErrorPage/>
    }
]);
