import React, { ReactNode, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import expandIcon from '../../../../assets/icons/arrow-dropdown-white.png';
import closeIcon from '../../../../assets/icons/red-close.png';
import { setMeetingView } from '../../../../utils/redux/virtual-meeting-slice';
import VirtualMeetingViewViewModel from '../../../view-models/component-view-models/virtual-meeting-view-viewmodel';

const SpinnerView = () => (
    <div className="w-screen h-screen flex items-center justify-center">
        <span className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></span>
    </div>
);

const VirtualMeetingView = () => {
    
    const {
        meetingData,
        isMeetOpen,
        userState,
        dispatch,
        isMenuOpen,
        handleCloseMeetingView,
        setIsMenuOpen,
        handleEndMeeting,
        virtualClassToken,
    } = VirtualMeetingViewViewModel();

    if(!meetingData && !isMeetOpen) return null

    return (
        <>
            <div onClick={() => dispatch(setMeetingView(true))} className='flex items-center gap-2 px-4 py-2 border-2 rounded-full cursor-pointer active:scale-95 transition-all'>
                <img 
                    src='/assets/images/online.png'
                    className='h-8 w-8 animate-pulse'
                />
                <div className='text-slate-600 font-semibold'>In Meeting</div>
            </div>
            {ReactDOM.createPortal(
                <div
                    className={`fixed top-0 left-0 bottom-0 right-0 ${isMeetOpen ? 'z-[10000]': '-z-[10000]'} bg-slate-200 rounded scroll-smooth`}
                >
                    <JitsiMeeting
                        domain = { 'virtual-class.sparts.app' }
                        roomName = {meetingData?.attributes?.meetingName?.replace(/[-:.]/g, '') ?? 'NOT FOUND'}
                        jwt={virtualClassToken}
                        configOverwrite = {{
                            startWithAudioMuted: true,
                            disableModeratorIndicator: false,
                            startScreenSharing: true,
                            enableEmailInStats: false,
                        }}
                        interfaceConfigOverwrite = {{
                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
                        }}
                        userInfo = {{
                            email: userState?.user?.email ?? 'NOT FOUND',
                            displayName: userState?.user?.username ?? 'NOT FOUND',
                        }}
                        onApiReady = {(externalApi) => {
                            // here you can attach custom event listeners to the Jitsi Meet External API
                            // you can also store it locally to execute commands
                        }}
                        getIFrameRef = { (iframeRef) => { iframeRef.style.height = 'calc(100vh - 30px)'; } }
                        spinner = { SpinnerView }
                        onReadyToClose={handleEndMeeting}
                    />
                    <div 
                        className={`bg-slate-800 w-full absolute top-full left-1/2 -translate-x-1/2 -translate-y-full transition-all delay-200
                            ${isMenuOpen ? 'h-[200px]' : 'h-[30px]'}
                        `}
                    >
                        <div 
                            className={`w-full flex items-center justify-center cursor-pointer ${isMenuOpen ? 'h-0' : 'translate-y-0'}`}
                            onClick={() => setIsMenuOpen(true)}
                        >
                            <img 
                                className={`w-8 h-8 transition-all ${isMenuOpen ? 'hidden' : 'rotate-180'}`}
                                src={expandIcon}
                            />
                        </div>
                        <div className={`flex w-full items-center absolute justify-center transition-all ${isMenuOpen ? 'translate-y-[-100px]' : 'translate-y-[200px]'}`}>
                            <img 
                                className='w-14 h-14 rounded-full border-2 border-red-500 p-2 cursor-pointer transition-all shadow-[0_0_20px_5px_rgba(255,0,0,0.8)] active:scale-95'
                                src={closeIcon}
                                onClick={() => setIsMenuOpen(false)}
                            />
                        </div>
                        <div className={`sm:px-[120px] px-[20px] py-[20px] transition-all ${isMenuOpen ? 'translate-y-0' : 'translate-y-[200px]'}`}>
                            <button
                                className='w-full py-2 bg-slate-700 text-white rounded-md active:scale-95 transition-all'
                                onClick={handleCloseMeetingView}
                            >
                                Go back
                            </button>
                        </div>
                    </div>
                </div>,
                document.getElementById('meeting') as HTMLElement
            )}
        </>
    )
};

export default VirtualMeetingView;
