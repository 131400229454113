import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMeetingData, setMeetingView } from '../../../utils/redux/virtual-meeting-slice';
import { RootState } from '../../../utils/redux/store';
import { VirtualMeetingService } from '../../../services/virtual-meeting-service';
import { VirtualMeetingDataWrapper } from '../../../models/virtual-meeting/virtual-meeting-data-wrapper';
import { VirtualMeetingPostData } from '../../../models/virtual-meeting/virtual-meeting-post-data';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACTIVE_VIRTUAL_MEETING_OF_USER, VIRTUAL_CLASS_AUTH_TOKEN } from '../../../utils/constants/constants';

const VirtualMeetingViewViewModel = () => {

    const meetingData = useSelector((state: RootState) => state.virtualMeeting.activeMeetingData?.data);
    const isMeetOpen = useSelector((state: RootState) => state.virtualMeeting.isMeetOpen);
    const userState = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {isLoading: isVirtualClassTokenFetching, data: virtualClassToken } = useQuery({
        queryKey: [VIRTUAL_CLASS_AUTH_TOKEN],
        queryFn: () => VirtualMeetingService.instance.getVirtualMeetingToken(),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
        refetchInterval: 1000 * 60 * 2,
    });

    useEffect(() => {
        if(meetingData && meetingData?.attributes?.hasEnded) {
            dispatch(setActiveMeetingData(null));
        }
    },[meetingData])

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    
    const handleCloseMeetingView = () => {
        setIsMenuOpen(false);
        dispatch(setMeetingView(false));
    }

    const putInstantMeeting = async (
        data: VirtualMeetingPostData
    ): Promise<VirtualMeetingDataWrapper | undefined> => {
        const response = await VirtualMeetingService.instance.putNewMeeting(meetingData?.id ?? 0, data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateInstantMeeting,
        isLoading: isInstantMeetingUpdating,
    } = useMutation(putInstantMeeting, {
        onSuccess: data => {
            dispatch(setActiveMeetingData(null));
            handleCloseMeetingView();
            queryClient.invalidateQueries(ACTIVE_VIRTUAL_MEETING_OF_USER);
        },
        onError: error => {
            console.log("Failed to update instant meeting!", error);
        },
    });

    const handleEndMeeting = () => {
        updateInstantMeeting({
            hasEnded: true,
            hasEndedOn: new Date().toISOString(),
        })
    }

    return {
        isLoading: isVirtualClassTokenFetching || isInstantMeetingUpdating,
        meetingData,
        virtualClassToken: virtualClassToken?.data?.token ?? '',
        isMeetOpen,
        userState,
        dispatch,
        isMenuOpen,
        handleCloseMeetingView,
        setIsMenuOpen,
        handleEndMeeting,
    }
}

export default VirtualMeetingViewViewModel