import React, { useEffect, useState } from 'react';
import NavMenu from '../../components/widgets/nav-menu';
import filterIcon from '../../../assets/icons/filter.png';
import calendarIcon from '../../../assets/icons/calendar.png';
import listIcon from '../../../assets/icons/list.png';
import style from './style.module.css';
import Loader from '../../components/bits/loader';
import ScheduleViewmodel from '../../view-models/schedule-viewmodel';
import ScheduleListItem from '../../components/pieces/schedule-list-item';
import PopupModal from '../../components/widgets/popup-modal';
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import { divide } from 'lodash';
import { ClassData } from '../../../models/class/class-data';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import { Roles } from '../../../utils/auth/roles';

const Schedule = () => {
    const {
        classData,
        isSuccess,
        goScheduleDetails,
        isFilterModalOpen,
        handleFilterModalClose,
        handleFilterPopupModalOpen,
        attendanceListCount,
        tileContentForEdit,
        handleDayClickOnEditMode,
        isCalendarVisible,
        handelToggles,
        userState,
        filterStudentsForDay,
        tileClassName,
        handleToggleFilterStudentsForDay,
        currentDate,
    } = ScheduleViewmodel();

    return (
        <NavMenu>
            {userState?.userDetails?.attributes?.scheduleAccess !== 'noAccess' ||
            userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                <div className={style.container}>
                    <div className={style.scheduleTabsToggles}>
                        <div
                            className={`flex items-center justify-center h-16 w-auto sm:w-40 rounded-md p-4 transition-all cursor-pointer shadow-2xl bg-white border-2 border-gray-500`}
                        >
                            Schedule
                            <img
                                className={style.filterSwitchOption}
                                src={filterIcon}
                                alt="filter"
                                onClick={handleFilterPopupModalOpen}
                            />
                        </div>
                        <div className="flex items-center justify-center sm:justify-end gap-4 h-full w-full">
                            <section className="flex sm:w-auto flex-col items-center bg-white rounded-md px-4 py-[4px]">
                                <h3 className="text-sm font-semibold">Only Students for Today</h3>
                                <div className="flex items-center">
                                    <p
                                        className={`text-sm text-gray-600 ${!filterStudentsForDay ? 'scale-125' : ''} transition-all`}
                                    >
                                        Off
                                    </p>
                                    <div
                                        onClick={handleToggleFilterStudentsForDay}
                                        className="relative bg-slate-200 rounded-[35px] flex items-center m-[0.5em_1.25em] w-[3.5em] min-w-[3.5em] cursor-pointer h-8"
                                    >
                                        <div
                                            className={`absolute bg-slate-600 rounded-full h-6 w-6 m-1 transition-transform duration-300 ${filterStudentsForDay ? 'translate-x-full' : 'translate-x-0'}`}
                                        ></div>
                                    </div>
                                    <p
                                        className={`text-sm text-gray-600 ${filterStudentsForDay ? 'scale-125' : ''} transition-all`}
                                    >
                                        On
                                    </p>
                                </div>
                            </section>
                            <div className="h-full flex items-center sm:mr-4 bg-white rounded-lg px-4">
                                {isCalendarVisible ? (
                                    <div
                                        className={`p-2 hover:bg-white rounded cursor-pointer border-4 bg-white shadow-inner`}
                                        onClick={() => handelToggles(false)}
                                    >
                                        <img
                                            src={listIcon}
                                            className="h-10 w-10"
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`p-2 hover:bg-white rounded cursor-pointer border-4 bg-white shadow-inner`}
                                        onClick={() => handelToggles(true)}
                                    >
                                        <img
                                            src={calendarIcon}
                                            className="h-10 w-10"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={style.mainContentConatiner}>
                        <div className={style.filtersContainer}>
                            <div className={style.filterTitle}>
                                Filters
                                <img
                                    className={style.filterIcon}
                                    src={filterIcon}
                                    alt="filter"
                                />
                            </div>
                        </div>
                        {isSuccess ? (
                            <div className={style.contentContainer}>
                                {/* <div className={`${viewData ? 'hidden' : 'block'}`}> */}
                                <div
                                    className={`w-full absolute transition-all duration-500 transform max-h-full overflow-y-auto thin-scrollbar ${isCalendarVisible ? 'translate-x-[-100%] opacity-0' : ''}`}
                                >
                                        {classData.filter(item => item.attributes.status !== "INACTIVE")
                                        .map((item, index) => (
                                        <ScheduleListItem
                                            data={item}
                                            key={index}
                                            onViewClicked={goScheduleDetails}
                                            currentDate={currentDate}
                                            attendanceListCount={attendanceListCount}
                                            isCalendarMode={false}
                                            filterStudentsForDay={filterStudentsForDay}
                                        />
                                    ))}
                                </div>
                                <div
                                    className={`flex items-center justify-center mt-2 sm:m-4 w-full h-full absolute transition-all duration-500 transform ${isCalendarVisible ? 'translate-x-0' : 'translate-x-full'}`}
                                >
                                    <Calendar
                                        tileContent={tileContentForEdit}
                                        tileClassName={tileClassName}
                                        className={`font-semibold !border-4 !border-black-400 !w-[96vw] sm:!w-[800px] rounded-lg !bg-slate-100}`}
                                        onClickDay={(value, event) =>
                                            handleDayClickOnEditMode(value)
                                        }
                                        maxDate={new Date()}
                                    />
                                </div>
                                {/* </div> */}
                                {/* <div className={`${viewData ? 'block' : 'hidden'}`}>
                                    <div className="flex flex-col">
                                        {eventsToday?.map((item, index) => (
                                            <ScheduleListItem
                                                data={item}
                                                key={index}
                                                attendanceListCount={attendanceListCount}
                                                onViewClicked={() =>
                                                    selectedDate &&
                                                    goScheduleDetailsCal(item.id, selectedDate)
                                                }
                                                isCalendarMode={true}
                                                // filterStudentsForDay={filterStudentsForDay}
                                            />
                                        ))}
                                    </div>
                                    <div
                                        className="bg-[#455A64] text-white font-bold m-4 p-4 text-center rounded"
                                        onClick={() => setViewData(!viewData)}
                                    >
                                        Back
                                    </div>
                                </div> */}
                            </div>
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <AccessDeniedPage />
            )}
            <PopupModal
                isOpen={isFilterModalOpen}
                handleModalClose={handleFilterModalClose}
            >
                <div>Filters</div>
            </PopupModal>
        </NavMenu>
    );
};

export default Schedule;
